<script setup lang="ts">
import {
  getServerState,
  useRoute,
  addServerState,
  useSSRContext,
} from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import {
  computed,
  defineAsyncComponent,
  ref,
  onServerPrefetch,
  onMounted,
  hydrateOnVisible,
  hydrateOnIdle,
} from 'vue'
import type { Market } from '@ocp-zmarta/content-sdk'
import type { Brand } from '@ocp-zmarta/content-sdk'
import { castBrandAndMarketToSite, getNavigationMenu } from '@ocp-zmarta/content-sdk'
import { formatFooterContent, type Content, type FormattedFooterContent } from '@/format-content'
import { rootMargin } from '@/utils/constants/components'
import useScriptsFooter from '@/composables/useScriptsFooter'

// Components
const FooterBorder = defineAsyncComponent(async () => await import('@/footer/components/FooterBorder.vue'))
const FooterMenu = defineAsyncComponent({
  loader: async () => await import('@/footer/components/FooterMenu.vue'),
  hydrate: hydrateOnVisible(rootMargin),
})
const FooterLinks = defineAsyncComponent({
  loader: async () => await import('@/footer/components/FooterLinks.vue'),
  hydrate: hydrateOnVisible(rootMargin),
})
const FooterPartOfZmarta = defineAsyncComponent({
  loader: async () => await import('@/footer/components/FooterPartOfZmarta.vue'),
  hydrate: hydrateOnVisible(rootMargin),
})
const FooterContact = defineAsyncComponent({
  loader: async () => await import('@/footer/components/FooterContact.vue'),
  hydrate: hydrateOnVisible(rootMargin),
})
const FooterStickyCtaSpacing = defineAsyncComponent({
  loader: async () => await import('@/footer/components/FooterStickyCtaSpacing.vue'),
  hydrate: hydrateOnVisible(rootMargin),
})
const ZgChat = defineAsyncComponent({
  loader: async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgChat),
  hydrate: hydrateOnIdle(),
})
const BrokerTheme = defineAsyncComponent(async () => await import('@/footer/theme/BrokerTheme.vue'))

const ctx = import.meta.env.SSR
  ? useSSRContext()
  : {
      market: getServerState('market'),
      brand: getServerState('brand'),
      isSweden: getServerState('isSweden'),
      isNorway: getServerState('isNorway'),
      isZmarta: getServerState('isZmarta'),
      isBroker: getServerState('isBroker'),
      isElskling: getServerState('isElskling'),
      isFinland: getServerState('isFinland'),
      config: getServerState('config'),
    }

const formattedFooterContent = ref<FormattedFooterContent | object>()

onServerPrefetch(async () => {
  const footerContent: Content = await getNavigationMenu({ site: castBrandAndMarketToSite(ctx?.brand as Brand, ctx?.market as Market) })
  formattedFooterContent.value = formatFooterContent({ footerContent })

  addServerState('footer-content', formattedFooterContent.value)
})

onMounted(() => {
  formattedFooterContent.value = getServerState('footer-content')
})

const route = useRoute()

const minimalParam = ref<boolean>(route?.searchParams.get('minimal') === 'true')
const showBorder = computed(() => !ctx?.isBroker && !minimalParam.value)
const showLinks = computed(() => ctx?.isBroker && !minimalParam.value)
const showPartOfZmarta = computed(() => ctx?.isBroker && !ctx?.isFinland && !minimalParam.value)
const stickyCtaSpacingParam = ref<boolean>(route?.searchParams.get('sticky_cta_spacing') === 'true')
const showMenu = computed(() => !ctx?.isBroker && !minimalParam.value && Object.keys(formattedFooterContent).length)

const isTestcafe = ref<boolean>(!!route?.searchParams.get('testcafe'))

const showChat = ref<boolean>(!ctx?.isElskling && !!(ctx?.isNorway || ctx?.isFinland) && !isTestcafe.value)
const chatType = ctx?.isFinland ? 'leadoo' : 'zopim'

// Add scripts
if (!isTestcafe.value) {
  useScriptsFooter({
    isSweden: ctx?.isSweden,
    isNorway: ctx?.isNorway,
    isZmarta: ctx?.isZmarta,
    isBroker: ctx?.isBroker,
    isElskling: ctx?.isElskling,
  })
}
</script>

<template>
  <footer
    :class="['zmarta-footer', `theme--${ctx?.brand}`, {
      'zmarta-footer--default': !minimalParam,
      'zmarta-footer--minimal': minimalParam
    }]"
  >
    <footer-border v-if="showBorder" />
    <footer-menu v-if="showMenu" />
    <footer-links v-if="showLinks" />
    <footer-part-of-zmarta v-if="showPartOfZmarta" />
    <footer-contact />
    <footer-sticky-cta-spacing v-if="stickyCtaSpacingParam" />
    <zg-chat v-if="showChat" :brand="ctx?.brand" :market="ctx?.market" :type="chatType" />
    <broker-theme v-if="ctx?.isBroker" />
  </footer>
</template>

<style lang="scss" scoped>
  .zmarta-footer {
    position: relative;

    .footer {
      display: flex;
      flex-direction: column;
    }
  }
</style>
