import { useServerHead } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'

export default function usescript ({
  isSweden = false,
  isNorway = false,
  isZmarta = false,
  isBroker = false,
  isElskling = false,
}) {
  useServerHead(() => {
    const createScript = (uniqueId: string) => ({
      id: 'knowledge-base',
      type: 'text/javascript',
      src: `https://zmarta.kb.connectel.app/faq/widget.js?uniqueId=${uniqueId}`,
      defer: true,
    })

    const script = []

    if (isZmarta && isSweden) {
      script.push(createScript('3Bf5OgbVoEUgwXusA0pv')) // Connectel chat for zmartas.se
    }

    if (isElskling) {
      if (isSweden) {
        script.push(createScript('a08SYUfmbvCPHZ0JB1sb')) // Connectel chat for elskling.se
      } else if (isNorway) {
        script.push(createScript('KKP4U5C98NPox4R71yAn')) // Connectel chat for elskling.no
      }
    }

    if (isBroker && isSweden) {
      script.push(createScript('xjPgnj4PooDcrZtNcUSL')) // Connectel chat for freedomfinance.se
    }

    return {
      script,
    }
  })
}
